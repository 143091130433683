import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import Section from "../components/section"
import Heading from "../components/heading"
import MultiItemCarousel from "../components/multiItem-crousel"
import AmazonList from "../components/amazon-list"

import { freeData } from '../components/materials-free-data';
import { storeData } from '../components/materials-store-data';

const MateriaisPage = () => (
  <Layout>
    <SEO title="Materiais" />
      <Section>
        <Heading center className="cl-identity">Materiais de Apoio</Heading>

        <Heading secondary center>
          Conheça os materiais que produzi para lhe auxiliar na prática da Educação Domiciliar
        </Heading>
        <br/>

        <Heading secondary center className="cl-identity font-weight-bold">LOJA</Heading>
        <MultiItemCarousel multiData={storeData} />
        <br/>

        <Heading secondary center className="cl-identity font-weight-bold">GRATUITOS</Heading>
        <MultiItemCarousel multiData={freeData} />

        <Heading secondary center className="cl-identity font-weight-bold">LIVROS AMAZON</Heading>
        <AmazonList />

      </Section>
  </Layout>
)

export default MateriaisPage
