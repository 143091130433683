import * as React from "react"
// import AmazonProduct from "./amazon-product"
// import { Container } from "react-bootstrap"
// import { products } from "./amazon.data"

export default function AmazonList() {
  return (
    <div className="d-flex justify-content-center align-items-center flex-column">
      {/*<Container className="d-flex justify-content-center align-items-center flex-wrap" style={{marginTop: "20px"}}>*/}
      {/*  {products.map((product, index) => (*/}
      {/*    <AmazonProduct key={index} src={product.src} />*/}
      {/*  ))}*/}
      {/*</Container>*/}

      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.amazon.com.br/shop/amaehomeschooler?ref_=cm_sw_r_cp_ud_aipsfshop_aipsfamaehomeschooler_QFNKM4T94CBPXM68X0WN"
        className="font-weight-bold text-decoration-none cl-identity mt-3"
      >
        Acesse a nossa loja completa
      </a>
    </div>
  )
}
